<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
Schedule Online Course
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
         <b-col md="4">
            <b-form-group
              label="Select Category"

              label-for="blog-edit-category"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                 <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.category"
                label="type"
                :options="categoriesList"
                :reduce="(val) => val.id"
                    @input="getCoursesByCategory(addCourseForm.category)"
              />
               <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col md="4">
            <b-form-group
              label="Select Course"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.course"
                @input="getCourseDate(addCourseForm.course)"
                label="name"
                :options="coursesList"
                :reduce="(val) => val.id"
             >
             <template #no-options="{ search, searching, loading }" v-if="!getCourse">
              Select Category First ...
             </template>
             <template #no-options="{ search, searching, loading }" v-else>
              Loading ...
             </template>
  </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           

          
           <b-col md="12" xl="12">
               <b-form-group
           
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
            
               <flat-pickr
                v-model="addCourseForm.date"
                class="form-control "
                style='display: none !important;'
                :config="{ mode: 'multiple',inline: true,showMonths: 3}"
              />
               </validation-provider>

              </b-form-group>
            </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormDatepicker
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
import flatPickr from 'vue-flatpickr-component'

export default {
  setup() {
 
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
    const Tabimage = ref("");
const coursesList=ref([])
const getCourse=ref(false)

      const categoriesList=ref([])
   
     
   const getCoursesByCategory = (id) => {
      addCourseForm.course = "";
      getCourse.value=true
      store
        .dispatch("categories/getCoursesByCategory", {
       
       'online':1,
      ' category_id':id

   
   })
        .then((response) => {
          console.log("response", response.data);
          coursesList.value = response.data.data;
        });
    };
 store.dispatch('categories/AllCategory')
        .then(response => {
          console.log("response",response.data.data)
          categoriesList.value=response.data.data
        })
    const addCourseForm = reactive({
     
      course: "",
      category: "",
      date:[]
      
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()

    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("course_id", addCourseForm.course);
formData.append("category_id", addCourseForm.category);
formData.append("online", 1);
formData.append("date", addCourseForm.date);

           store.dispatch('schedule/addCourseOnline',formData)
        .then(response => {
          addCourseForm.course=''
          addCourseForm.category=''
          addCourseForm.date=''
           Vue.swal({
                       title: "Schedule Course Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    const getCourseDate=(id)=>{
      // addCourseForm.date=[]
      console.log('addCourseForm.date',addCourseForm.date)
      let data=[]
      store
        .dispatch("schedule/getCourseSchedule", {
       
          "filter[course_id]":id,
          "filter[online]":1
         
        })
        .then((response) => {
          console.log("coursesList", response.data);
          response.data.forEach(el=>{
            data.push(el.date)
          })
          addCourseForm.date=data
        });
    };
    
    return {
      getCourseDate,
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
  categoriesList,
     
getCoursesByCategory,
      save,
      getCourse,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    flatPickr,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormDatepicker
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

</style>
